import React, { useState } from "react"
import Image from "./image"
import { Link } from "gatsby"

const Header = () => {
  return (
    <nav className="navbar has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div className="navbar-item has-text-centered">
          <Link className="is-uppercase is-size-4" to="/">
            Tabian-Dee
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Header