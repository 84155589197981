import React from "react"
import Layout from "../components/layout"
import NotFound from "../components/notfound"

const NotFoundPage = ({ ...props }) => {
  console.log("404page", props)
  return props.location.pathname === "/404" ? (
    <Layout>
      <NotFound />
    </Layout>
  ) : (
    <Layout noFooter />
  )
}

export default NotFoundPage
