/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"

const Layout = ({ children, noFooter }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="columns is-tablet is-marginless has-padding-y-3">
          <div className="column">
            <div>
              <main>{children}</main>
              {/* {!noFooter && (
                <footer>
                  © {new Date().getFullYear()}, Made by
                  {` `}
                  <a href="https://www.tabiandee.com/">TabianDee.com Alright Reserved.</a>
                </footer>
              )} */}
              <footer>
                © {new Date().getFullYear()}, Made by
                {` `}
                <Link href="/">TabianDee.com Alright Reserved.</Link>
              </footer>
            </div>
          </div>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
